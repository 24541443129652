._3tcyg {
  position: relative;
  width: 200px;
  height: 40px
}

._3LDgJ {
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #c1bcbc;
  border-radius: 5px;
  background-color: inherit;
  color: inherit
}

._WbEAz {
  position: absolute;
  z-index: 2000;
  overflow: auto;
  width: 100%;
  color: inherit;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
}

._r3jpc {
  margin: 0;
  padding: 0;
  padding-top: 5px;
  text-align: center;
  border-radius: 5px;
  text-align: left;
  overflow-y: auto;
}

._2iQTD {
  list-style-type: none;
  padding: 6px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
}

._1GXAI {
  position: absolute;
  right: 0;
  top: 55%;
  transform: translate(0, -50%);
  margin-right: 5px;
}

._2e4AG {
  position: absolute;
  left: 0;
  top: 55%;
  transform: translate(0, -50%);
  margin-left: 8px;
}
